/* eslint-disable */
import { Flex, Button, Text } from '@noahswap/uikit'
import QuestionHelper from 'components/QuestionHelper'
import { useTranslation } from '@noahswap/localization'
import { useGasPriceManager, useDefaultGasPrice } from 'state/user/hooks'
import { GAS_PRICE_GWEI, GAS_PRICE } from 'state/types'
import { parseUnits } from '@ethersproject/units'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ChainId } from '@noahswap/sdk'
import { set } from 'lodash'

const GasSettings = () => {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const [gasPrice, setGasPrice] = useGasPriceManager()
  const defaultGasPrice = useDefaultGasPrice()

  // console.log('ccc', gasPrice)
  return (
    <Flex flexDirection="column">
      <Flex mb="12px" alignItems="center">
        <Text>{t('noah220')}</Text>
        <QuestionHelper text={t('noah219')} placement="top-start" ml="4px" />
      </Flex>
      <Flex flexWrap="wrap">
        <Button
          mt="4px"
          mr="4px"
          border
          scale="sm"
          onClick={() => {
            setGasPrice(GAS_PRICE_GWEI.rpcDefault)
          }}
          variant={gasPrice === GAS_PRICE_GWEI.rpcDefault ? 'primary' : 'tertiary'}
        >
          {t('noah216')}
        </Button>
        <Button
          mt="4px"
          mr="4px"
          border
          scale="sm"
          onClick={() => {
            setGasPrice((Number(defaultGasPrice) + Number(parseUnits('30', 'gwei').toString())).toString())
            // setGasPrice('930000000000')
            // setGasPrice(GAS_PRICE_GWEI.fast)
          }}
          variant={
            gasPrice === (Number(defaultGasPrice) + Number(parseUnits('30', 'gwei').toString())).toString()
              ? 'primary'
              : 'tertiary'
          }
        >
          {t('noah217')}
        </Button>
        <Button
          mr="4px"
          mt="4px"
          border
          scale="sm"
          onClick={() => {
            // setGasPrice(GAS_PRICE_GWEI.instant)
            setGasPrice((Number(defaultGasPrice) + Number(parseUnits('60', 'gwei').toString())).toString())
          }}
          variant={
            gasPrice === (Number(defaultGasPrice) + Number(parseUnits('60', 'gwei').toString())).toString()
              ? 'primary'
              : 'tertiary'
          }
        >
          {t('noah218')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default GasSettings
